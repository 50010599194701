import React, { Component } from 'react';
import { FormGroup, ControlLabel, FormControl, Grid, Row, Col, Checkbox, Button } from 'react-bootstrap';
import history from './history';

export default class EditableLicenseDetail extends Component {

    constructor(props) {
        super(props);

        this.state = {
            license: this.props.license.content
        };
    }
    
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let newLicense = { ...this.state.license, [name]: value};
        this.setState({
            license: newLicense
        });
    }

    handleModuleChange(event) {
        const moduleName = event.target.name;
        const checked = event.target.checked;

        const license = this.state.license;
        const oldModules = license.modules;
        var newModules;
        if (checked) {
            newModules = [...oldModules, moduleName];
        } else {
            newModules = [...oldModules];
            var index = oldModules.indexOf(moduleName);
            if (index > -1) {
                newModules.splice(index, 1);
            }
        }

        this.setState({
            license: { ...license, modules: newModules }
        });
    }

    goBack() {
        history.push("/");
    }
    
    save() {
        const self = this;
        const licenseId = this.props.license.id;
        const licenseContent = this.state.license;
        this.props.api.saveLicense(licenseId, licenseContent)
                            .then(response => {
                                self.goBack();
                            })
                            .catch(error => {
                                console.log(error);
                            });
    }
        
    isModuleSelected(module) {
        return this.state.license.modules.includes(module.id);
    }

    render() {
        let checkboxes = this.props.modules.map(module => <Checkbox checked={this.isModuleSelected(module)} key={module.id} name={module.id} onChange={this.handleModuleChange.bind(this)}>{module.name}</Checkbox>);
        
        return (
            <Grid>
                <Row>
                    <Col xs={12} sm={8} smOffset={2}>
                        <h2>License details</h2>
                        <FormGroup controlId="company">
                            <ControlLabel>Company</ControlLabel>
                            <FormControl
                                type="text"
                                name="companyName"
                                value={this.state.license.companyName}
                                placeholder="Enter company name"
                                onChange={this.handleInputChange.bind(this)}
                            />
                        </FormGroup>

                        <FormGroup controlId="project">
                            <ControlLabel>Project</ControlLabel>
                            <FormControl
                                type="text"
                                name="project"
                                value={this.state.license.project}
                                placeholder="Enter project title"
                                onChange={this.handleInputChange.bind(this)}
                                />
                        </FormGroup>

                        <FormGroup controlId="notes">
                            <ControlLabel>Notes</ControlLabel>
                            <FormControl
                                componentClass="textarea"
                                name="description"
                                value={this.state.license.description}
                                placeholder="Notes"
                                rows="5"
                                onChange={this.handleInputChange.bind(this)}
                                />
                        </FormGroup>

                        <FormGroup controlId="modules">
                            <ControlLabel>Modules</ControlLabel>

                            { checkboxes }
                        </FormGroup>

                        <Button bsSize="large" onClick={this.goBack.bind(this)} block>Back</Button>
                        <Button bsStyle="primary" bsSize="large" onClick={this.save.bind(this)} block>Save</Button>
                    </Col>
                </Row>
            </Grid>
        );
    }
    
}