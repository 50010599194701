import { Component } from 'react';
import { connect } from 'react-redux';

class EnsureLoggedInContainerView extends Component {

    componentDidMount() {
        const { auth, isAuthenticated } = this.props
        if (!auth.isAuthenticated()) {
            auth.login();
        } else {
            if (!isAuthenticated) {
                // resync auth state - user is authenticated but state is out of sync
                auth.dispatchAuthenticationState();
            }
        }
    }

    render() {
        const { auth } = this.props

        if (auth.isAuthenticated()) {
            return this.props.children
        } else {
            return null
        }
    }

}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.isAuthenticated
    }
};

const mapDispatchToProps = dispatch => {
    return {}
};

const EnsureLoggedInContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(EnsureLoggedInContainerView);

export default EnsureLoggedInContainer;
