import axios from 'axios';

const baseApiUrl = '/api/v1/';

export default class ApiClient {

    constructor(auth) {
        this.auth = auth;
        this.callApi = this.callApi.bind(this);
        this.authHeaders = this.authHeaders.bind(this);
        this.getLicenses = this.getLicenses.bind(this);
        this.downloadLicense = this.downloadLicense.bind(this);
    }

    authHeaders() {
        return { 'Authorization': `Bearer ` + this.auth.getAccessToken() };
    }

    callApi(path, options) {
        const headers = this.authHeaders();
        return axios.get(baseApiUrl + path, { headers, ...options });
    }

    getLicenses() {
        return this.callApi('license/');
    }

    getLicense(id) {
        return this.callApi('license/' + id);
    }

    downloadLicense(id) {
        return this.callApi('license/download/' + id, {responseType: 'blob'});
    }

    getLicensedModules() {
        return this.callApi('module/');
    }

    saveLicense(licenseId, licenseContent) {
        const headers = this.authHeaders();

        const license = {
            id: licenseId,
            content: licenseContent
        };
        return axios.post(baseApiUrl + 'license/',  license, { headers });
    }
}