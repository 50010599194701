export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_PROFILE = 'SET_PROFILE';

export function setAuthenticated(authenticated) {
  return { type: SET_AUTHENTICATED, authenticated };
}

export function setProfile(profile) {
  return { type: SET_PROFILE, profile };
}
