import React, { Component } from 'react';
import ReadOnlyLicenseDetail from './ReadOnlyLicenseDetail';
import EditableLicenseDetail from './EditableLicenseDetail';
import axios from 'axios';

export default class LicenseDetail extends Component {
    
    constructor(props) {
        super(props);

        this.licenseId = this.props.match.params.licenseId;

        this.state = {
            license: {},
            modules: [],
            loaded: false,
            readOnly: false
        };
    }

    componentDidMount() {
        let api = this.props.api;
        var self = this;

        if (this.licenseId) {
            axios
                .all([api.getLicense(this.licenseId), api.getLicensedModules()])
                .then(axios.spread(function (license, modules) {
                    self.setState({ 
                        license: license.data, 
                        modules: modules.data, 
                        loaded: true 
                    });
                }))
                .catch(error => {
                    console.log(error);
                });
        } else {
            api.getLicensedModules()
                .then(response => {
                    self.setState({ 
                        license: {
                            content: {
                                modules: []
                            }
                        },
                        modules: response.data, 
                        loaded: true 
                    });

                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    render() {
        if (this.state.loaded) {
            if (this.state.readOnly) {
                return <ReadOnlyLicenseDetail license={ this.state.license } modules={ this.state.modules } {...this.props} />
            } else {
                return <EditableLicenseDetail license={ this.state.license } modules={ this.state.modules } {...this.props} />
            }
        } else {
            return (
                <div>
                    Loading...
                </div>
            );
        }
    }
}    