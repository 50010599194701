import auth0 from 'auth0-js';
import { AUTH_CONFIG } from './auth0-variables';
import history from '../history';
import { setAuthenticated, setProfile } from '../actions';

const homeRoute = '/';

export default class Auth {
  userProfile;
  tokenRenewalTimeout;

  auth0 = new auth0.WebAuth({
    domain: AUTH_CONFIG.domain,
    clientID: AUTH_CONFIG.clientId,
    redirectUri: AUTH_CONFIG.callbackUrl,
    audience: AUTH_CONFIG.apiUrl,
    responseType: 'token id_token',
    scope: 'openid profile email'
  });

  constructor(getStore) {
    this.getStore = getStore;
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getProfile = this.getProfile.bind(this);
    this.setSession = this.setSession.bind(this);
    this.dispatchAuthenticationState = this.dispatchAuthenticationState.bind(this);
    this.dispatchProfile = this.dispatchProfile.bind(this);
    this.scheduleRenewal();
  }

  login() {
    this.auth0.authorize();
  }

  handleAuthentication() {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        history.replace(homeRoute);
      } else if (err) {
        history.replace(homeRoute);
        console.log(err);
        alert(`Error: ${err.error}. Check the console for further details.`);
      }
    });
  }

  setSession(authResult, renewal) {
    // Set the time that the access token will expire at
    let expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );

    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);

    // schedule a token renewal
    this.scheduleRenewal();

    if (!renewal) {
      // dispatch auth state to Redux store
      this.dispatchAuthenticationState();
  
      // navigate to the home route
      history.replace(homeRoute);
    }
  }

  dispatchAuthenticationState() {
    let authenticated = this.isAuthenticated();
    this.getStore().dispatch(setAuthenticated(authenticated));

    this.dispatchProfile();
  }

  dispatchProfile() {
    this.getProfile((err, profile) => {
      this.getStore().dispatch(setProfile(profile));
    });
  }

  getAccessToken() {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      throw new Error('No access token found');
    }
    return accessToken;
  }

  getProfile(cb) {
    let accessToken = this.getAccessToken();
    this.auth0.client.userInfo(accessToken, (err, profile) => {
      if (profile) {
        this.userProfile = profile;
      }
      cb(err, profile);
    });
  }

  logout() {
    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('scopes');
    this.userProfile = null;
    clearTimeout(this.tokenRenewalTimeout);
    this.tokenRenewalTimeout = null;

    // clear auth state from Redux store
    this.getStore().dispatch(setAuthenticated(false));
    this.getStore().dispatch(setProfile(null));
    
    // navigate to the home route
    history.replace(homeRoute);
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }

  renewToken() {
    console.log('Renewing access token');

    this.auth0.renewAuth(
      {
        audience: AUTH_CONFIG.apiUrl,
        redirectUri: AUTH_CONFIG.silentAuthRedirect,
        usePostMessage: true
      },
      (err, result) => {
        if (err) {
          console.log(`Could not get a new token using silent authentication (${err.error}).`);
        } else {
          this.setSession(result, true);
          console.log(`Successfully renewed auth!`);
        }
      }
    );
  }

  scheduleRenewal() {
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    const delay = expiresAt - Date.now() - 60000; // one minute before expiry
    if (delay > 0) {
      if (!this.tokenRenewalTimeout) {
        this.tokenRenewalTimeout = setTimeout(() => {
          this.tokenRenewalTimeout = null;
          this.renewToken();
        }, delay);
      }
    }
  }
}
