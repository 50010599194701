import { combineReducers } from 'redux'
import { SET_AUTHENTICATED, SET_PROFILE } from './actions'

function isAuthenticated(state = false, action) {
  switch (action.type) {
    case SET_AUTHENTICATED:
      return action.authenticated;
    default:
      return state;
  }
}

function profile(state = {}, action) {
  switch (action.type) {
    case SET_PROFILE:
      return action.profile;
    default:
      return state;
  }
}

const reducers = combineReducers({
  isAuthenticated,
  profile
});

export default reducers;