import React, { Component } from 'react';
import { Navbar, Glyphicon } from 'react-bootstrap';
import { connect } from 'react-redux';

class NavHeaderView extends Component {

    goTo(route) {
        this.props.history.replace(`/${route}`);
    }

    logout() {
        this.props.auth.logout();
    }

    render() {
        return (
            <div>
                <Navbar fluid>
                    <Navbar.Header>
                        <Navbar.Brand>
                            <a onClick={this.goTo.bind(this, '')}>chy.stat License Portal</a>
                        </Navbar.Brand>
                    </Navbar.Header>
                    {
                        this.props.isAuthenticated && (
                            <Navbar.Collapse>
                                <Navbar.Text pullRight>
                                    <img src={this.props.profile.picture} className="profile-picture img-circle" alt="" />
                                    {this.props.profile.name}
                                    <a className="logout" onClick={this.logout.bind(this)} title="Logout">
                                        <Glyphicon glyph="log-out" />
                                    </a>
                                </Navbar.Text>
                            </Navbar.Collapse>
                        )
                    }
                </Navbar>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.isAuthenticated,
        profile: state.profile
    }
};

const mapDispatchToProps = dispatch => {
    return {}
};

const NavHeader = connect(
    mapStateToProps,
    mapDispatchToProps
)(NavHeaderView);

export default NavHeader;
