import React, { Component } from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import '../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import FileSaver from 'file-saver';
import { Link } from 'react-router-dom';
import {LinkContainer} from 'react-router-bootstrap';
import { Button, Grid, Row, Col } from 'react-bootstrap';
import moment from 'moment';
import history from './history';

export default class LicenseList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            licenses: [],
            loaded: false
        };

        this.options = {
            defaultSortName: 'issued',
            defaultSortOrder: 'desc',
            onRowClick: function(row){
                history.push(`/detail/${row.id}`);
            }
        };
    }

    componentDidMount() {
        this.props.api.getLicenses()
                            .then(response => {
                                this.setState({ licenses: response.data, loaded: true });
                            })
                            .catch(error => {
                                console.log(error);
                            });
    }

    downloadLicense(licenseId, e) {
        this.props.api.downloadLicense(licenseId)
                                .then(response => {
                                    FileSaver.saveAs(response.data, 'license.lic');
                                })
                                .catch(error => {
                                    console.log(error);
                                });
        e.stopPropagation();
    }

    formatDate(cell) {
        if (cell) {
            return moment(cell).format('LLL');
        } else {
            return "";
        }
    }

    downloadButton(cell) {
        return (
            <a onClick={ this.downloadLicense.bind(this, cell) } title="Download license">
                <i className="fa fa-download" aria-hidden="true"/>
            </a>
        );
    }

    detailButton(cell) {
        return (
            <Link to={"/detail/" + cell} title="Detail">
                <i className="fa fa-pencil-square-o" aria-hidden="true"/>
            </Link>
        );
    }

    render() {
        if (this.state.loaded) {
            let licenses = this.state.licenses.map(license => {
                return {
                    id: license.id,
                    companyName: license.content.companyName,
                    project: license.content.project,
                    issued: license.content.issued,
                    issuer: license.content.issuer,
                    description: license.content.description
                };
            });
            return (
                <Grid fluid>
                    <Row>
                        <Col xs={12}>
                            <BootstrapTable className="license-list-table" data={licenses} options={this.options} striped hover pagination>
                                <TableHeaderColumn isKey hidden dataField='id'>ID</TableHeaderColumn>
                                <TableHeaderColumn dataField='companyName' dataSort={true}>Company</TableHeaderColumn>
                                <TableHeaderColumn dataField='project' dataSort={true}>Project</TableHeaderColumn>
                                <TableHeaderColumn dataField='issued' dataSort={true} dataFormat={ this.formatDate.bind(this) }>Issued</TableHeaderColumn>
                                <TableHeaderColumn dataField='issuer' dataSort={true}>Issuer</TableHeaderColumn>
                                <TableHeaderColumn dataField='description' dataSort={true}>Description</TableHeaderColumn>
                                <TableHeaderColumn dataField='id' width="32" dataFormat={ this.downloadButton.bind(this) } />
                                <TableHeaderColumn dataField='id' width="32" dataFormat={ this.detailButton.bind(this) } />
                            </BootstrapTable>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <LinkContainer to="/issue/">
                                <Button bsStyle="primary" bsSize="large" block>Issue new license</Button>
                            </LinkContainer>
                        </Col>
                    </Row>
                </Grid>
            );
        } else {
            return (
                <div>
                    Loading...
                </div>
            );
        }
    }
}