import React, { Component } from 'react';
import { FormGroup, ControlLabel, FormControl, Grid, Row, Col, Checkbox, Button } from 'react-bootstrap';
import history from './history';

export default class ReadOnlyLicenseDetail extends Component {

    goBack() {
        history.push("/");
    }

    isModuleSelected(module) {
        return this.props.license.content.modules.includes(module.id);
    }

    render() {
        let checkboxes = this.props.modules.map(module => <Checkbox checked={this.isModuleSelected(module)} readOnly key={module.id}>{module.name}</Checkbox>);
        
        return (
            <Grid>
                <Row>
                    <Col xs={12} sm={8} smOffset={2}>
                        <h2>License details</h2>
                        <FormGroup controlId="company">
                            <ControlLabel>Company</ControlLabel>
                            <FormControl
                                type="text"
                                value={this.props.license.content.companyName}
                                placeholder="Enter company name"
                                disabled={true}
                            />
                        </FormGroup>

                        <FormGroup controlId="project">
                            <ControlLabel>Project</ControlLabel>
                            <FormControl
                                type="text"
                                value={this.props.license.content.project}
                                placeholder="Enter project title"
                                disabled={true}
                            />
                        </FormGroup>

                        <FormGroup controlId="notes">
                            <ControlLabel>Notes</ControlLabel>
                            <FormControl
                                componentClass="textarea"
                                value={this.props.license.content.description}
                                placeholder="Notes"
                                rows="5"
                                disabled={true}
                            />
                        </FormGroup>

                        <FormGroup controlId="modules">
                            <ControlLabel>Modules</ControlLabel>

                            { checkboxes }
                        </FormGroup>

                        <Button bsStyle="primary" bsSize="large" onClick={this.goBack.bind(this)} block>Back</Button>
                    </Col>
                </Row>
            </Grid>
        );
    }
    
}