import React, { Component } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import Auth from './Auth/Auth';
import Callback from './Callback/Callback';
import NavHeader from './NavHeader';
import NotFound from './NotFound';
import LicenseList from './LicenseList';
import LicenseDetail from './LicenseDetail';
import EnsureLoggedInContainer from './EnsureLoggedInContainer';
import history from './history';
import { getStore } from './index';
import ApiClient from './Api/ApiClient'

const auth = new Auth(getStore);
const api = new ApiClient(auth);

const handleAuthentication = (nextState, replace) => {
    if (/access_token|id_token|error/.test(nextState.location.hash)) {
        auth.handleAuthentication();
    }
};

const renderMergedProps = (component, ...rest) => {
    const finalProps = Object.assign({}, ...rest);
    return (
        React.createElement(component, finalProps)
    );
};

const PropsRoute = ({ component, ...rest }) => {
    return (
        <Route {...rest} render={routeProps => {
            return renderMergedProps(component, routeProps, rest);
        }} />
    );
};

export default class App extends Component {

    render() {
        return (
            <div>
                <NavHeader auth={auth} history={history}></NavHeader>

                <Router history={history}>
                    <Switch>
                        <Route
                            path="/callback"
                            render={props => {
                                handleAuthentication(props);
                                return <Callback {...props} />;
                            }}
                        />
                        <PropsRoute component={EnsureLoggedInContainer} auth={auth}>
                            <Route exact path="/" render={props => <LicenseList api={api} {...props} />} />
                            <Route exact path="/issue" render={props => <LicenseDetail api={api} {...props} />} />
                            <Route exact path="/detail/:licenseId" render={props => <LicenseDetail api={api} {...props} />} />
                        </PropsRoute>
                        <Route component={NotFound} />
                    </Switch>
                </Router>
            </div>
        );
    }
}